import { useEffect, useState } from 'react';
import { utils } from './util';
import { useMsal,AuthenticatedTemplate,UnauthenticatedTemplate } from '@azure/msal-react';
import './App.css';
import { loginRequest } from './authConfig';

function App() {
  const [activeAccount, setActiveAccount] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [startBtn, setStartBtn] = useState(false)
  const [stopBtn, setStopBtn] = useState(false)
  const [status, setStatus] = useState('');
  const [startVMBtn, setStartVMBtn] = useState(false);
  const [stopVMBtn, setStopVMBtn] = useState(false);
  const [vmStatus, setVmStatus] = useState('');


  const { instance } = useMsal();
useEffect(()=> {
    instance.initialize().then(() => {
    instance.acquireTokenPopup(loginRequest).then((token) => {
      setActiveAccount(instance.getActiveAccount());
      setAuthToken(token.accessToken);
  })});
},[]);

useEffect(() => {
  fetch(utils.CHECK_TASKMATE_STATUS_URL, {
    method: "POST",
    headers: new Headers({ 'Authorization': `Bearer ${authToken}` })
  })
    .then((res) => res.json())
    .then((data) => {
      setStatus(data.properties.powerState.code);
    });
  fetch(utils.VM_STATUS_URL, {
    method: "POST",
    headers: new Headers({ 'Authorization': `Bearer ${authToken}` })
  })
    .then((res) => res.json())
    .then((data) => {
      setVmStatus(data.statuses[1].displayStatus);
    })
}, [startVMBtn,stopVMBtn,stopBtn,startBtn, authToken]);

  function manageAks(action) {
    const url = action === "start" ? utils.START_TASKMATE_ENVIRONMENT_URL
      : utils.STOP_TASKMATE_ENVIRONMENT_URL;
    fetch(url, {
      method: "POST",
      headers: new Headers({ 'Authorization': `Bearer ${authToken}` }),
    }).then((res) =>{ setStartBtn(true);
    setStopBtn(true);
    setTimeout(() => this.setState({ startBtn: false }), 300000);
    setTimeout(() => this.setState({ stopBtn: false }), 300000);
    })};

  function manageVM(action) {
    const url = action === "start" ? utils.START_VM_MACHINE_URL
      : utils.STOP_VM_MACHINE_URL;
    fetch(url, {
      method: "POST",
      headers: new Headers({ 'Authorization': `Bearer ${authToken}` }),
    }).then((res) =>  {
        setStartVMBtn(true);
        setStopVMBtn(true);
        setTimeout(() => this.setState({ startVMBtn: false }), 300000);
        setTimeout(() => this.setState({ stopVMBtn: false }), 300000);
      });
  }

  const statusColor = status === "Running" ? "runningStatus" : "stoppedStatus";
  const vmStatusColor = vmStatus === "VM deallocated" ? "stoppedStatus" : "runningStatus";
  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ?
          <header className="App-header">
            <div className='warning-container'>
              <h1 className='warning'>Don't repeatedly stop and start TaskMate environment. This can result in errors. Once your TaskMate enviroment is stopped, you should wait at least 15-30 minutes before starting it again</h1>
            </div>
            <div className="actions-container">
              <h2 className='actions-status'>TaskMate environment is: <span className={statusColor}>{status}</span></h2>
              <button className='button' disabled={startBtn} onClick={() => manageAks("start")}>Start TaskMate Environment</button>
              <button className='button' disabled={stopBtn} onClick={() => manageAks("stop")}>Stop TaskMate Environment</button>
            </div>
            <div className="actions-container">
              <h2 className='actions-status'>TaskMate working machine is: <span className={vmStatusColor}>{vmStatus}</span></h2>
              <button className='button' disabled={startVMBtn} onClick={() => manageVM("start")}>Start TaskMate Machine</button>
              <button className='button' disabled={stopVMBtn} onClick={() => manageVM("stop")}>Stop TaskMate Machine</button>
            </div>
          </header>
          : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className='App'>Access denied</div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
